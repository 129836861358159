@import 'shared/styles/palette';

.create-chat {
    &__close-button {
        margin: -15px -15px 10px auto !important;
    }

    &__button {
        border: 1px solid $sun-orange !important;
        color: $sun-orange !important;
        width: 40px !important;
        height: 40px !important;
        margin: auto !important;
    }

    &__input {
        margin-bottom: 20px !important;
    }
}
