@import 'shared/styles/palette';

.header {
    position: sticky;
    top: 0;
    z-index: 1;

    background: $white;
    box-shadow: 0px 3px 20px rgba(89, 89, 89, .2);

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        gap: 10px;

        width: 100%;
        max-width: 1440px;
        height: 102px;

        margin: auto;
        padding-left: 54px;
        padding-right: 26px;

        &_top {
            padding-left: 26px;
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: 550px) {
            gap: 15px;
        }
    }

    @media screen and (max-width: 1100px) {
        &__content {
            padding-left: 5%;
            padding-right: 5%;
        }
    }

    @media screen and (max-width: 920px) {
        &__content {
            height: 80px;
        }
    }

    @media screen and (max-width: 500px) {
        &__content {
            height: 61px;
        }
    }
}

.hamburger {
    width: 42px;
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .7s ease all;

    &__icon {
        position: relative;
        width: 42px;
        height: 3px;
        background-color: $black;

        &::before {
            position: absolute;
            left: 0;
            top: -10px;
            content: '';
            width: 42px;
            height: 3px;
            background-color: $black;
            transition: transform .2s ease-in, top .2s linear .2s;
        }

        &::after {
            position: absolute;
            left: 0;
            top: 10px;
            content: '';
            width: 42px;
            height: 3px;
            background-color: $black;
            transition: transform .2s ease-in, top .2s linear .2s;
        }

        &_opened {
            background-color: transparent;

            &::before {
                transform: rotate(45deg);
                top: 0;
                transition: top .2s linear, transform .2s ease-in .2s;
            }

            &::after {
                transform: rotate(-45deg);
                top: 0;
                transition: top .2s linear, transform .2s ease-in .2s;
            }
        }
    }
}
