
.error-fallback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;

    &__content {
        text-align: center;
        margin: 30px;
    }

    &__heading {
        font-size: 24px;
    }
}
