@import 'shared/styles/palette';

.chat-bot {
    min-height: 100vh;

    &__content {
        display: flex;
        max-width: 1280px;
        min-height: calc(100vh - 202px);
        margin: 0 auto;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }
      
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
      
    ::-webkit-scrollbar-thumb {
        background: rgba($light-violet, .5);
    }
      
    ::-webkit-scrollbar-thumb:hover {
        background: $light-violet;
    }

    @media screen and (max-width: 550px) {
        &__content {
            min-height: calc(100vh - 81px);
        }
    }
}
