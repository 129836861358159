$black: #000000;
$white: #ffffff;

//$error: #d32f2f;

//$red: #F85454;
//$light-red: #FFF0F0;

//$link: #0563c1;

$light-blue: #8a92b5;

$orange: #ed833a;

$grey-1: #F9F9F9;

$orange-1: #F39100;
$orange-light-3: #FEFDFB;

$grey: #f7f6f4;
$orange: #FF7300;
$sun-orange: #FCC000;
$orange-1: #f39100cc;
$border: #131313;

$text: #4B4A4F;

$violet: #BEB3FF;

$light-violet: #d9d3fa;
