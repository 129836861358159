@import 'shared/styles/palette';

button.chat-ai-button {
    box-shadow: none;
    font-size: 14px;
    text-transform: none;
    border-color: $sun-orange;
    color: $sun-orange;

    &_text-transform {
        text-transform: uppercase;
    }

    &_contained-primary {
        background-color: $sun-orange;
        color: $black;
    }

    &:hover {
        background-color: $orange-1;
        border-color: $orange-1;
        color: $white;
    }
}
