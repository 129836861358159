@import 'shared/styles/palette';

.beta {
    background-color: $orange-1;
    color: $white;
    border-radius: 10px;
    font-size: 24px;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (max-width: 550px) {
        font-size: 18px;
    }

    @media screen and (max-width: 350px) {
        display: none;
    }
}
