@import 'shared/styles/palette';

.line-loader__container {
    height: 20px;
    width: 35px;
    margin-left: 20px;
    position: relative;
    bottom: 4px;
}

.line-loader, .line-loader:before, .line-loader:after {
    border-radius: 50%;
    width: 2em;
    height: 2em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
}

.line-loader {
    color: $violet;
    font-size: 5px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
    position: absolute;
    top: 0;
}

.line-loader:before,
.line-loader:after {
    content: '';
    position: absolute;
}

.line-loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
}
.line-loader:after {
    left: 3.5em;
}
  
@keyframes bblFadInOut {
    0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
    40% { box-shadow: 0 2.5em 0 0 }
}
      