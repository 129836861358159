@import 'shared/styles/palette';

.user {
    position: relative;
    margin-left: -10px;

    &__icon {
        background-color: $white !important; 
        border: 1px solid rgba($black, .3);
        color: rgba($black, .3) !important;
    }

    &__menu {
        background-color: $white;
        position: absolute;
        display: flex;
        flex-direction: column;
        box-shadow: 3px 3px 5px $grey;
        border-radius: 5px;
        border: 1px solid rgba($black, .1);
        right: 0;
        margin-top: 7px;
        margin-right: 7px;
        animation: 0.2s linear forwards fadeIn;
    }
}

.openMenu {
    display: none;
}
