@import 'shared/styles/palette';

.footer {
    height: 100px;
    width: 100%;

    &__content {
        display: flex;
        flex-direction: column;
        background-color: $orange-light-3;
    }

    &__text {
        font-size: 12px;
        max-width: 1440px;
        margin: 30px auto 0;
        padding: 20px;

        a {
            text-decoration: none;
        }
    }

    &__logo {
        margin-left: auto;

        .logo {
            width: 95px;
        }
    }

    @media screen and (max-width: 550px) {
        display: none;
    }
}
