@import 'shared/styles/palette';

.confirmation-dialog {
    &__content {
        display: inline-block;

        padding: 10px 0;

        font-size: 1.2em;
    }
}
