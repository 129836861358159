@import 'shared/styles/palette';

.chat-ai-text-field {
    margin: 0 !important;
    padding: 0 !important;

    input {
        padding: 10px 14px !important;
    }

    &-outlined {
        border-color: $grey;
    }

    .MuiOutlinedInput-root {
        background-color: $white !important;
    }
}
