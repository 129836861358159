@import 'shared/styles/palette';

.chat-menu {
    position: relative;
    margin: auto;

    &__icon {
        width: 40px !important;
        height: 40px !important;
        border: 1px solid $violet !important;
        color: $violet !important;
    }
    
    &__select {
        margin-top: 15px;
        background-color: $white;
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: column;
        box-shadow: 3px 3px 5px $grey;
        border-radius: 5px;
        border: solid 1px $violet;
        animation: 0.2s linear forwards fadeIn;
    }

    .element {
        padding: 7px 15px;

        &__name {
            margin-left: 15px;
        }
    }
}

.openMenu {
    display: none;
}
